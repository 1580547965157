
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import * as toastr from "toastr";
    import router from "@/router/router";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { LookupGroup, TriState } from "@/model/Enums";
    import { SupplierSearchParameters } from "@/model/SupplierSearchParameters";
    import { SiteSearchParameters } from "@/model/SiteSearchParameters";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { Supplier, ISupplier } from "@/model/Supplier";
    import { IRegionLookupItem } from '@/model/RegionLookupItem';
    import { ISite, Site } from "@/model/Site";
    
    @Component({
        components: { ApiButton }
    })
    export default class Suppliers extends Vue {

        async mounted() {
            this.searchParameters.isBuyerZone = true;
            this.searchParameters.buyerID = +this.$store.state.signedInUser.buyerID;
            this.searchParameters.isDormant = TriState.False;
            await Promise.all([
                this.loadCompanyTypeList(),
                this.loadSiteList(),
                this.loadRegionList()
            ]);
        }

        //
        // -- properties
        //

        searchIsBusy: boolean = false;
        searchParameters = new SupplierSearchParameters();
        totalCount = -1;
        supplierList: Array<Supplier> = [];
        follow = { 
            supplierID: 0, 
            supplierName: "", 
            amFollowing: false 
        };

        companyTypeList: Array<ILookupItem> = [];

        private regionList: Array<IRegionLookupItem> = [];
        get regionOptions(): Array<ILookupItem> { 
            const filtered = this.regionList.filter(lu => lu.id > 0 && !lu.isArchived && lu.buyerID === this.searchParameters.buyerID);
            return filtered.length === 0 
                ? [ new LookupItem( { id: 0, description:  "(No regions for this buyer!)", isArchived: false }) ]
                : [ new LookupItem( { id: 0, description:  "Ignore region", isArchived: false }), ...filtered];
        }

        private siteList: Array<ISite> = [];
        get siteOptions(): Array<ISite> { 
            const filtered = this.siteList.filter(s => s.id > 0 && !utils.hasDateValue(s.deleted) && s.regionID === this.searchParameters.regionID);
            return filtered.length === 0 
                ? [ Site.emptySiteOption("(No locations for region)") ]
                : [ Site.emptySiteOption("Any location"), ...filtered];
        }

        get roleOptions(): Array<ILookupItem> { return [
            new LookupItem({ id: 0, description: "Any Role", isArchived: false }),
            new LookupItem({ id: 1, description: "Consultant", isArchived: false }),
            new LookupItem({ id: 2, description: "Contractor", isArchived: false }),
            new LookupItem({ id: 3, description: "Consultant & Contractor", isArchived: false })
        ];}

        get selectedRole(): number {
            if (this.searchParameters.isConsultant && this.searchParameters.isContractor) return 3;
            else if (this.searchParameters.isConsultant) return 1;
            else if (this.searchParameters.isContractor) return 2;
            else return 0;
        }
        set selectedRole(value: number) {
            this.searchParameters.isConsultant = value == 1 || value == 3;
            this.searchParameters.isContractor = value == 2 || value == 3;
            this.refreshSearch();
        }

        //
        // -- watchers
        //

        @Watch("searchParameters.regionID")
        onRegionIdChanged() {
            if (this.searchParameters.siteID === 0) this.refreshSearch();
            else this.searchParameters.siteID = 0;
        }

        @Watch("searchParameters.siteID")
        onSiteIdChanged() {
            this.refreshSearch();
        }

        @Watch("searchParameters.amFollowing")
        onFollowingChanged() {
            this.refreshSearch();
        }

        //
        // -- methods
        //

        private async loadCompanyTypeList() {
            this.companyTypeList = await apiClient.get(`/api/lookup/search/${LookupGroup.CompanyType}`);
        }

        async loadRegionList() {
            this.regionList = await apiClient.get("api/region/lookups");
        }

        private async loadSiteList() {
            const parms = new SiteSearchParameters();
            //parms.regionID = this.regionSupplier.regionID;
            // load ALL sites and filter them here (might have to revisit this if there are 1000s of sites)
            this.siteList = await apiClient.post("/api/site/search", parms);
        }

        async edit(supplierData: ISupplier) {
            router.push("/buyerZone/supplier/" + supplierData.id);
        }

        showFollowDialogue(supplierData: ISupplier) {
            this.follow.supplierID = supplierData.id;
            this.follow.supplierName = supplierData.name;
            this.follow.amFollowing = supplierData.amFollowing;
            this.$bvModal.show("followDialogue");
        }

        hideFollowDialogue() {
            this.$bvModal.hide("followDialogue");
            utils.resetObject(this.follow);
        }

        async saveFollow(event: Event, isFollow: boolean) {
            await apiClient.get(`/api/supplier/${isFollow ? "follow" : "unfollow"}?supplierID=${this.follow.supplierID}`, event);
            toastr.success( this.follow.supplierName, isFollow ? "Now Following" : "No Longer Following");           
            this.$bvModal.hide("followDialogue");
            this.refreshSearch();
        }

        resetSearch(event?: Event) {
            utils.resetObject(this.searchParameters);
            this.searchParameters.isBuyerZone = true;
            this.searchParameters.buyerID = +this.$store.state.signedInUser.buyerID;
            this.searchParameters.isDormant = TriState.False;
            this.refreshSearch(event);
        }

        refreshSearch(event?: Event) {
            this.supplierList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.supplierList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.supplierList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async search(event?: Event) {
            if (this.searchIsBusy) {
                console.log("Skipped search because busy...");
                return;
            }
            this.searchIsBusy = true;
            const response = await apiClient.post("/Api/Supplier/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.supplierList.push(...response.list.map((s: ISupplier) => new Supplier(s)));
            this.searchIsBusy = false;
        }
    }
